<template>
  <b-modal
    id="lead-list-add-new-modal"
    ref="leadListAddNewModal"
    v-model="modalUp"
    no-close-on-backdrop
    bg-variant="white"
    size="xmd"
    :title="`Create Lead - ${websiteDataClientName}`"
    header-class="text-primary"
    scrollable
    @hidden="close()"
  >
    <template #default>
      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" id="unique_id_dv">
          <!-- <basic-information-lead
            ref="refBasicInformationLead"
            :user-data="userData"
            :type-form="newLead"
          />
          <lead-information-lead :user-data="userData" />
          <billing-information-lead :user-data="userData" /> -->
          <LeadPersonalInformation
            ref="refBasicInformationLead"
            :user-data="userData"
            :type-form="newLead"
          />
          <LeadContactInformation :type-form="newLead" :user-data="userData" />
          <LeadInformation ref="refLeadInformation" :user-data="userData" />
        </b-form>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-row class="px-3 py-2">
        <b-col cols="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="info"
            @click="
              resetuserData();
              $refs.refFormObserver.reset();
            "
            >Reset</b-button
          >
        </b-col>
        <b-col cols="6">
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="
                resetuserData();
                $refs.refFormObserver.reset();
                close();
              "
              >Cancel</b-button
            >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="onSubmit"
            >
              <template v-if="isLoading">
                <b-spinner small />
                <span>Loading...</span>
              </template>
              <span v-else>Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { BForm, BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import BasicInformationLead from "./BasicInformationLead.vue";
import BillingInformationLead from "./BillingInformationLead.vue";
import LeadInformationLead from "./LeadInformationLead.vue";
import LeadPersonalInformation from "@/views/commons/components/leads/leads-form-components/LeadPersonalInformation.vue";
import LeadInformation from "@/views/commons/components/leads/leads-form-components/LeadInformation.vue";
import LeadContactInformation from "@/views/commons/components/leads/leads-form-components/LeadContactInformation.vue";
export default {
  components: {
    LeadPersonalInformation,
    LeadInformation,
    LeadContactInformation,
    BasicInformationLead,
    LeadInformationLead,
    BillingInformationLead,
    BForm,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    websiteDataClient: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const resetRowData = () => {};
    const { getValidationState, resetForm } = formValidation(resetRowData);
    return {
      getValidationState,
      resetForm,
      blankUserData: {
        first_name: this.websiteDataClient.first_name,
        middle_name: this.websiteDataClient.middle_name,
        last_name: this.websiteDataClient.last_name,
        email: this.websiteDataClient.email,
        programs: [],
        dob: "",
        language: "",
        stateLead: null,
        social: "",
        ssn: "",
        itin: "",
        other: "",
        phone: this.formatPhoneNumber(this.websiteDataClient.phone_number),
        mobile: this.formatPhoneNumber(this.websiteDataClient.phone_number),
        address: {
          prename: "main",
          streetReal: "",
          street: "",
          city: "",
          state: "CA",
          zipcode: "",
          country: "United States",
        },
        origin_country: 146,
        another_address: "",
        otherAddress: {
          prename: "origin",
          streetReal: "",
          street: "",
          city: "",
          state: "",
          zipcode: "",
          country: "United States",
        },
        user_id: null,
        source_id: "",
        leadstatus_id: "",
        sourcesname_id: "",
        cardNumber1: "",
        cardNumber2: "",
        cardNumber3: "",
        cardNumber4: "",
        cardExpiMonth: "",
        cardExpiYear: "",
        cardHoldername: "",
        cardSecurityCode: "",
        cardAddress: {
          prename: "card",
          streetReal: "",
          street: "",
          city: "",
          state: "",
          zipcode: "",
          country: "United States",
        },
        programId: null,
        work: "",
        creditReport: true,
        payment: "0",
        ammount: "",
        description: "",
        cardNumber: "",
        typeCredit: "0",
        dateOnline: "",
        plataform: null,
        usernameOnline: "",
        passwordonline: "",
        memberNumberOnline: "",
        work_status: "",
        website_origin_id: this.websiteDataClient.website_origin_id,
      },
      modalUp: false,
      userData: {},
      required,
      alphaNum,
      email,
      isLoading: false,
      newLead: "newLead",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
      G_STATUS_LEADS: "CrmLeadStore/G_STATUS_LEADS",
      G_SOURCE_NAMES: "CrmGlobalStore/G_SOURCE_NAMES",
      G_SELLERS: "CrmGlobalStore/G_SELLERS",
    }),
    websiteDataClientName() {
      return this.websiteDataClient.full_name;
    },
  },
  created() {
    this.modalUp = true;
    this.resetuserData();
  },
  methods: {
    ...mapActions({
      A_SET_LEADS: "CrmLeadStore/A_SET_LEADS",
    }),
    resetuserData() {
      this.blankUserData.user_id = this.currentUser.user_id;
      this.userData = JSON.parse(JSON.stringify(this.blankUserData));
    },
    getSelectValue(element, state) {
      if (element && state && this[state] && this[state].length) {
        const index = this[state].map((el) => el.id).indexOf(element);
        if (index !== -1) return this[state][index];
      }
      return new Object();
    },
    async onSubmit() {
      try {
        const isValidated = await this.$refs.refFormObserver.validate();
        if (!isValidated) return;
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.isLoading = true;
        let route = "";
        switch (this.currentUser.modul_id) {
          case 2:
            route = "lead-show";
            break;
          case 3:
            route = "bussiness-lead-show";
            break;
          case 4:
            route = "administration-lead-show";
            break;
          case 5:
            route = "debtsolution-lead-show";
            break;
          case 6:
            route = "creditexperts-lead-show";
            break;
          case 7:
            route = "boostcredit-lead-show";
            break;
          case 8:
            route = "tax-research-lead-show";
            break;
          case 10:
            route = "claimdepartment-lead-show";
            break;
          case 11:
            route = "specialists-leads-show";
            break;
        }

        if (
          ![
            this.userData.cardNumber1,
            this.userData.cardNumber2,
            this.userData.cardNumber3,
            this.userData.cardNumber4,
          ].includes("")
        ) {
          this.userData.cardNumber = `${this.userData.cardNumber1}-${this.userData.cardNumber2}-${this.userData.cardNumber3}-${this.userData.cardNumber4}`;
        }
        // VALIDATION ITIN & SSN
        const val = this.userData.social.substr(0, 1);
        // if (val == 9) {
        //   this.userData.itin = this.userData.social;
        // } else {
        //   this.userData.ssn = this.userData.social;
        // }
        const { role_id, user_id } = this.currentUser;
        this.userData.programs =
          this.$refs.refLeadInformation.returnProgramlist();
        const {
          email,
          first_name,
          last_name,
          middle_name,
          source_id,
          sourcesname_id,
          programId,
          phone,
          mobile,
          work,
          creditReport,
          payment,
          ammount,
          programs,
          leadstatus_id,
          address,
          description,
          cardExpiMonth,
          cardExpiYear,
          ssn,
          cardHoldername,
          cardNumber,
          cardSecurityCode,
          dob,
          cardAddress,
          typeCredit,
          dateOnline,
          plataform,
          usernameOnline,
          passwordonline,
          memberNumberOnline,
          language,
          itin,
          other,
          state_lead,
          another_address,
          otherAddress,
          origin_country,
          work_status,
          website_origin_id,
          gender,
          marital_status,
          contact_method,
          foreign_id,
        } = this.userData;
        const body = {
          id: "",
          email,
          user_id,
          first_name,
          last_name,
          middle_name,
          source_id,
          sourcesname_id,
          program_id: programId,
          phone,
          mobile,
          work,
          credit_report: creditReport,
          payment,
          ammount,
          program: programs.map((el) => ({
            id: el.id,
            value: el.label,
            name: el.label,
          })),
          leadstatus_id,
          street: address.street,
          city: address.city,
          state: address.state,
          country: address.country,
          zipcode: address.zipcode,
          description,
          card_expi_month: cardExpiMonth,
          card_expi_year: cardExpiYear,
          ssn,
          cardholdername: cardHoldername,
          cardnumber: cardNumber,
          cardsecuritycode: cardSecurityCode,
          dob,
          super: role_id,
          created_by: user_id,
          usercreator: user_id,
          datecreator: this.$moment(dob, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD"
          ),
          streetcard: cardAddress.street,
          citycard: cardAddress.city,
          zipcodecard: cardAddress.zipcode,
          statecard: cardAddress.state,
          countrycard: cardAddress.country,
          type_credit: typeCredit,
          dateonline: dateOnline,
          plataform,
          usernameonline: usernameOnline,
          passwordonline,
          membernumberonline: memberNumberOnline,
          language,
          itin,
          other,
          state_lead,
          another_address,
          otherstreet: otherAddress.street,
          othercity: otherAddress.city,
          otherstate: otherAddress.state,
          othercountry: otherAddress.country,
          otherzipcode: otherAddress.zipcode,
          originCountry: origin_country,
          lead_name: `${first_name} ${last_name}`, // fot table leads
          state_hour: this.getSelectValue(state_lead, "G_STATE_LEADS").label, // fot table leads
          status: this.getSelectValue(leadstatus_id, "G_STATUS_LEADS").label, // fot table leads
          source_name: this.getSelectValue(sourcesname_id, "G_SOURCE_NAMES")
            .label, // fot table leads
          owner: this.getSelectValue(user_id, "G_SELLERS").label, // fot table leads
          work_status,
          website_origin_id,
          gender,
          marital_status,
          contact_method,
          foreign_id,
        };
        const response = await this.A_SET_LEADS(body);
        if (response && (response.status == 200 || response.status == 201)) {
          this.isLoading = false;
          this.$refs.refFormObserver.reset();
          this.$emit("update:is-add-new-user-sidebar-active", false);
          this.resetuserData();
          const routeData = this.$router.resolve({
            name: route,
            params: { id: response.data.id },
          });
          window.open(routeData.href, "_blank");
          /* this.$router.push({ name: route, params: { id: response.data.id } }) */
          this.$emit("saveLead");
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            "Something went wrong."
          );
        }
        this.$emit("close");
      } catch (error) {
        console.log("Something went wrong onSubmit: ", error);
        this.isLoading = false;
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    close() {
      this.modalUp = false;
      this.$emit("close");
    },
    formatPhoneNumber(number) {
      // Convert the input to a string and remove any non-digit characters
      const cleanedNumber = String(number).replace(/\D/g, "");

      // Check if the number is formatted or not
      const isFormatted = cleanedNumber.length === 10;

      // Split the number into area code, first three digits, and last four digits
      const areaCode = cleanedNumber.slice(0, 3);
      const firstThreeDigits = cleanedNumber.slice(3, 6);
      const lastFourDigits = cleanedNumber.slice(6);

      // Format the number based on whether it is already formatted or not
      if (isFormatted) {
        return `(${areaCode}) ${firstThreeDigits}-${lastFourDigits}`;
      }
      return `(${areaCode}) ${firstThreeDigits}${
        lastFourDigits ? `-${lastFourDigits}` : ""
      }`;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
