export default [
  {
    key: 'full_name',
    label: 'Full Name',
    sortable: false,
    visible: true,
  },
  {
    key: 'phone_number',
    label: 'Phone',
    sortable: false,
    visible: true,
  },
  {
    key: 'website',
    label: 'Website',
    sortable: false,
    visible: true,
  },
  {
    key: 'email',
    label: 'Email',
    sortable: false,
    visible: true,
  },
  {
    key: 'commentary',
    label: 'Observation',
    sortable: false,
    visible: true,
  },
  {
    key: 'appointment_date',
    label: 'Appointment Date',
    sortable: false,
    visible: true,
  },
  {
    key: 'created_at',
    label: 'Created at',
    sortable: false,
    visible: true,
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
