<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalLeads"
        :paginate="paginate"
        :start-page="fromPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        @reload="myProvider"
        @onChangeCurrentPage="onChangeCurrentPage"
      >
        <b-table
          slot="table"
          ref="refTableWebsite"
          class="position-relative font-small-3"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="73vh"
          primary-key="id"
          empty-text="No matching records found"
          show-empty
          no-provider-filtering
          :fields="fields"
          :items="items"
          :per-page="paginate.perPage"
          :sort-by.sync="sortBy"
          :busy.sync="isBusy"
          :sort-desc.sync="isSortDirDesc"
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(phone_number)="data">
            <div class="text-center">
              <feather-icon
                icon="PhoneIcon"
                size="14"
              />
              {{ data.label }}
            </div>
          </template>
          <template #head(website)="data">
            <div class="text-center">
              <feather-icon
                icon="GlobeIcon"
                size="14"
              />
              {{ data.label }}
            </div>
          </template>
          <template #head(email)="data">
            <div class="text-center">
              <feather-icon
                icon="MailIcon"
                size="14"
              />
              {{ data.label }}
            </div>
          </template>
          <template #head(commentary)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(appointment_date)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(full_name)="data">
            <div v-if="data.item.has_lead_id">
              <router-link
                :class="textLink"
                :to="`/${routeModule}/leads/${data.item.lead_id}`"
                target="_blank"
              >
                {{ data.item.full_name }}
              </router-link>
            </div>
            <div v-else>
              {{ data.item.full_name }}
            </div>
          </template>
          <template #cell(phone_number)="data">
            <div class="text-center">
              {{ data.item.phone_number }}
            </div>
          </template>
          <template #cell(website)="data">
            <div class="text-center text-primary">
              {{ data.item.website }}
            </div>
          </template>
          <template #cell(email)="data">
            <div class="text-center text-primary">
              {{ data.item.email }}
            </div>
          </template>
          <template #cell(commentary)="data">
            <div class="text-center">
              {{ data.item.commentary }}
            </div>
          </template>
          <template #cell(appointment_date)="data">
            <div class="text-center">
              {{ data.item.appointment_date | myDateGlobalWithHour }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="text-center">
              {{ data.item.created_at | myDateGlobalWithHour }}
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="text-center">
              <div class="d-flex">
                <div>
                  <div v-if="data.item.has_lead_id === 1">
                    <tabler-icon
                      v-b-tooltip.hover.bottom="'This lead has been created'"
                      size="18"
                      icon="ChecksIcon"
                      class="text-success cursor-pointer"
                    />
                  </div>
                  <div v-else>
                    <tabler-icon
                      v-b-tooltip.hover.bottom="'Create a lead'"
                      size="18"
                      icon="NewSectionIcon"
                      class="text-primary cursor-pointer"
                      @click="openCreateLeadModal(data.item)"
                    />
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-b-tooltip.hover.bottom="`${data.item.is_seen == 0 ? 'Mark as seen' : 'Already seen'}`"
                    icon="EyeIcon"
                    :class="
                      data.item.is_seen == 0 ? 'text-danger cursor-pointer' : 'text-success'
                    "
                    @click="data.item.is_seen == 0 && markSeenWebsiteKLead(data.item.website_origin_id)"
                  />
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <create-lead-modal
      v-if="showCreateLeadModal"
      :website-data-client="websiteDataClient"
      @close="closeCreateLeadModal();"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue';
import Fields from '@/views/crm/views/Lead/lead-table/fields.website.data';
import CreateLeadModal from '@/views/crm/views/Lead/lead-module/save/LeadListAddNewModal.vue';
import Filters from '@/views/paragon/views/table_empty/filters';
import WebsiteLeads from '@/views/crm/views/Lead/lead-module/services/website-leads.services';

export default {
  components: {
    FilterSlot,
    CreateLeadModal,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    getTab() {
      return this.$route.meta.tab;
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      isBusy: false,
      fields: Fields,
      filter: Filters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      items: [],
      totalLeads: 0,
      perPage: 10,
      fromPage: 0,
      toPage: 0,
      currentPage: 1,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      showCreateLeadModal: false,
      isSortDirDesc: true,
      websiteDataClient: {},
    };
  },
  async created() {
  },
  mounted() {},
  methods: {
    ...mapActions({
      A_GET_W_WEBSITE_LEADS: 'CrmLeadStore/A_GET_W_WEBSITE_LEADS',
    }),
    async myProvider() {
      try {
        this.isBusy = true;
        const params = {
          page: this.paginate.currentPage,
          per_page: this.paginate.perPage,
          search_text: this.filterPrincipal.model,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          has_lead: this.filter[2].model,
        };
        const { data } = await this.A_GET_W_WEBSITE_LEADS(params);
        this.totalLeads = data.total;
        this.fromPage = data.from;
        this.toPage = data.to;
        this.isBusy = false;
        console.log(data.data);
        this.items = data.data;
      } catch (error) {
        console.log('Something went wrong WebsiteLeads provider', error);
        this.showToast(
          'danger',
          'top-right',
          'Oop!',
          'AlertOctagonIcon',
          this.getInternalErrors(error),
        );
        return [];
      }
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.myProvider();
    },
    openCreateLeadModal(item) {
      this.websiteDataClient = item;
      this.showCreateLeadModal = true;
    },
    async closeCreateLeadModal() {
      this.myProvider();
      await WebsiteLeads.updateLeadsUnseenCounter();
      this.websiteDataClient = null;
      this.showCreateLeadModal = false;
    },
    async markSeenWebsiteKLead(websiteOriginId) {
      const { isConfirmed } = await this.showConfirmSwal('Are you sure mark as seen?');
      if (!isConfirmed) return;
      try {
        const params = { website_origin_id: websiteOriginId };
        const response = await WebsiteLeads.markWebsiteLeadAsSeen(params);
        console.log(response);
        this.showToast(
          'success',
          'top-right',
          'Success!',
          'CheckIcon',
          'This lead has been marked as seen',
        );
        await WebsiteLeads.updateLeadsUnseenCounter();
        this.myProvider();
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
</script>
